import { useEffect, useRef, useState } from 'react';

export function useDebounceValue<T = any>(value: T, delay = 1000) {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const timerId = useRef<NodeJS.Timeout>();

    useEffect(() => {
        clearTimeout(timerId.current);
        timerId.current = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(timerId.current);
    }, [value, delay]);

    return debouncedValue;
}
