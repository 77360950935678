import React, { Fragment, Suspense, useEffect } from 'react';
import './assets/css/app.scss';
import './assets/css/fa-la-icons.scss';

import { Navigate, Route, Routes } from 'react-router-dom';
import {
    noHeaderRoutes,
    loginRoutes,
    mainRoutes,
    adminRoutes,
    adminAuthRoutes,
    telemarketerRoutes,
} from './Services/Routes';
import { Loader } from './Views/Common/Loader';
import MainLayout from './Layouts/MainLayout';
import { GetUserData, isUserLoggednIn } from './Services/Auth';
import { ReactNotifications } from 'react-notifications-component';
import AdminLayout from './Layouts/AdminLayout/AdminLayout';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { updateUser } from './store/slices/user/userSlice';
import { useAppDispatch } from './store/hooks';
import ProtectedRoute from './Layouts/ProtectedRoute';
import RedirectRoute from './Layouts/RedirectRoute';
import AdminProtectedRoutes from './Layouts/AdminLayout/AdminProtectedRoutes';

const NoHeaderLayout = React.lazy(() => import('./Layouts/NoHeaderLayout'));
const LoginLayout = React.lazy(() => import('./Layouts/LoginLayout'));

const App = () => {
    const dispatch = useAppDispatch();

    const loadUserData = async () => {
        if (isUserLoggednIn()) {
            var user = await GetUserData();
            dispatch(updateUser(user));
        }
    };

    useEffect(() => {
        loadUserData();
    }, []);

    return (
        <Fragment>
            <ReactNotifications />
            <Loader forceVisible={false} />
            <Suspense fallback={<Loader forceVisible={true} />}>
                <Routes>
                    <Route path='/admin/*' element={<AdminProtectedRoutes />}>
                        {adminRoutes.map((route, idx) =>
                            route?.element ? (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    element={
                                        <AdminLayout {...route.layoutProps}>
                                            {route.element}
                                        </AdminLayout>
                                    }
                                />
                            ) : null
                        )}
                        <Route
                            path='*'
                            element={<Navigate to='/admin/subscribers' />}
                        />
                    </Route>

                    {adminAuthRoutes.map((route, idx) =>
                        route?.element ? (
                            <Route
                                key={idx}
                                path={route.path}
                                element={
                                    <LoginLayout {...route.layoutProps}>
                                        {route.element}
                                    </LoginLayout>
                                }
                            />
                        ) : null
                    )}
                    <Route
                        path='/admin'
                        element={<Navigate to='/admin/subscribers' />}
                    />

                    {loginRoutes.map((route, idx) => {
                        return route.element ? (
                            <Route
                                key={idx}
                                path={route.path}
                                element={
                                    <LoginLayout {...route.layoutProps}>
                                        {route.element}
                                    </LoginLayout>
                                }
                            />
                        ) : null;
                    })}

                    {noHeaderRoutes.map((route, idx) => {
                        return route.element ? (
                            <Route
                                key={idx}
                                path={route.path}
                                element={
                                    <NoHeaderLayout {...route.layoutProps}>
                                        {route.element}
                                    </NoHeaderLayout>
                                }
                            />
                        ) : null;
                    })}

                    <Route
                        element={
                            <ProtectedRoute
                                authorizedRoles={[
                                    'telemarketer',
                                    'telemarketer_admin',
                                ]}
                            />
                        }
                    >
                        {telemarketerRoutes.map((route, idx) => {
                            return route.element ? (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    element={
                                        <MainLayout {...route.layoutProps}>
                                            {route.element}
                                        </MainLayout>
                                    }
                                />
                            ) : null;
                        })}
                    </Route>

                    <Route
                        element={
                            <ProtectedRoute authorizedRoles={['subscriber']} />
                        }
                    >
                        {mainRoutes.map((route, idx) => {
                            return route.element ? (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    element={
                                        <MainLayout {...route.layoutProps}>
                                            {route.element}
                                        </MainLayout>
                                    }
                                />
                            ) : null;
                        })}
                    </Route>

                    <Route path='/linkedin' element={<LinkedInCallback />} />
                    <Route path='*' element={<RedirectRoute />} />
                </Routes>
            </Suspense>
        </Fragment>
    );
};

export default App;
